import { Helmet } from "react-helmet-async";
import Approaching from "./components/Approaching";
import LetTalkNumbers from "./components/LetTalkNumbers";
import OpenRoles from "./components/OpenRoles";
import OurVerticals from "./components/OurVerticals";
import OvalloInfo from "./components/OvalloInfo";
import Banner from "./components/Banner";
import WeWorkingIn from "./components/WeWorkingIn";

const HomePage = () => {
  return (
    <div className="flex flex-col">
      <Helmet titleTemplate="Home" defaultTitle="Home">
        <meta name="description" content="Avallo application" />
      </Helmet>

      <Banner />
      <Approaching />
      <OvalloInfo />
      <WeWorkingIn />
      <OurVerticals />
      <LetTalkNumbers />
      <OpenRoles />
    </div>
  );
};

export default HomePage;
