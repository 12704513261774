import Logo from "../../../assets/logo/Footer/light-logo.svg";
import IC_DISCORD from "../../../assets/logo/Footer/ic_discord.svg";
import IC_MEDIUM from "../../../assets/logo/Footer/ic_medium.svg";
import IC_TELEGRAM from "../../../assets/logo/Footer/ic_telegram.svg";
import IC_TWITTER from "../../../assets/logo/Footer/ic_twitter.svg";
import IC_YOUTUBE from "../../../assets/logo/Footer/ic_youtube.svg";
import { ImgHTMLAttributes } from "react";
import { Button } from "@mui/material";
import Section from "../Layout";

type IconsProtype = Pick<ImgHTMLAttributes<HTMLImageElement>, "src" | "alt"> & {
  otherprop?: any;
};

const Footer = () => {
  const icons: IconsProtype[] = [
    {
      src: IC_TWITTER,
      alt: "Icon Twitter",
    },
    {
      src: IC_YOUTUBE,
      alt: "Icon Youtube",
    },
    {
      src: IC_MEDIUM,
      alt: "Icon Medum",
    },
    {
      src: IC_TELEGRAM,
      alt: "Icon Telegram",
    },
    {
      src: IC_DISCORD,
      alt: "Icon Discord",
    },
  ];

  return (
    <Section sectionClassNames="bg-primary">
      <div className="relative flex flex-col py-14 lg:py-20 w-10/12 mx-auto">
        <div className="flex max-md:flex-col gap-10">
          <div className="flex-1">
            <img src={Logo} alt="Logo" />
          </div>

          <div className="flex-1">
            <p className="text-white t_lg font-semibold">Join the team</p>
            <p className="text-white t_base mt-4 lg:mt-12 cursor-pointer">
              Interested in being part of the team? Check out careers
            </p>
          </div>

          <div className="flex-1 flex flex-col md:items-center">
            <div>
              <p className="text-white t_lg font-semibold">Get in touch</p>
              <p className="text-white t_base mt-4 lg:mt-12 cursor-pointer">
                Contact Us
              </p>
            </div>
          </div>

          <div className="flex-1">
            <div>
              <p className="text-white t_lg font-semibold">Connect with us</p>
            </div>
            <div className="mt-4 md:mt-8 flex flex-wrap gap-2 -mx-3">
              {icons.map((icon: IconsProtype, index: number) => (
                <div
                  className="cursor-pointer bg-dark_purple/40 p-3 rounded-lg flex items-center justify-center"
                  key={`ic_${index}`}
                >
                  <img src={icon.src} alt={icon.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-1 w-full flex max-md:flex-col md:items-center gap-10 justify-between mt-20">
          <p className="text-white t_base">
            © Ovallo 2023 Tower 42, 25 Old Broad Street, London EC2N 1HN
          </p>

          <div className="flex items-center gap-14">
            <p className="text-white t_base font-semibold cursor-pointer">
              Terms Of Use
            </p>
            <p className="text-white t_base font-semibold cursor-pointer">
              Terms Of Use
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Footer;
