import img_how_we_hire_1 from "../../../../../assets/images/culture/img_how_we_hire_1.png";
import img_how_we_hire_2 from "../../../../../assets/images/culture/img_how_we_hire_2.png";
import img_stage_2 from "../../../../../assets/images/culture/img_stage_2.png";
import ic_left_culture_2 from "../../../../../assets/images/culture/ic_left_culture_2.png";
import culture_2 from "../../../../../assets/images/culture/culture_2.png";
import culture_mask from "../../../../../assets/images/culture/culture_mask.png";
import Section from "../../../../components/Layout";

export const HowWeHire = () => {
  return (
    <Section>
      <div className="mt-20">
        <div className="absolute top-[500px] hidden lg:block">
          <img src={ic_left_culture_2} alt="Cover" />
        </div>

        <div className="mt-16 lg:mt-20 relative">
          <div className="md:grid gap-20 grid-cols-2 mt-8 mx-auto md:w-4/5">
            <div className="flex flex-col justify-center">
              <h2 className="text-dark_purple mb-8">How We Hire</h2>
              <p className="t_lg">
                Individuals are at the heart of our endeavors, and we are strong
                proponents of providing equal opportunities to all. Our
                uncomplicated recruitment approach centers on identifying
                talented individuals who resonate with our company's values and
                culture. This fosters an inclusive atmosphere where everyone can
                flourish.
              </p>
            </div>
            <div className="flex items-center justify-center">
              <img src={culture_2} alt="Why You Will Love Working Here" />
            </div>
          </div>
        </div>

        <div className="mt-16 lg:mt-20 relative">
          <div className="md:grid gap-20 grid-cols-2 mt-8 mx-auto md:w-4/5">
            <img src={img_how_we_hire_2} alt="Cover" />
            <div className="flex flex-col justify-center">
              <p className="t_lg">
                Individuals are at the heart of our endeavors, and we are strong
                proponents of providing equal opportunities to all. Our
                uncomplicated recruitment approach centers on identifying
                talented individuals who resonate with our company's values and
                culture. This fosters an inclusive atmosphere where everyone can
                flourish.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col mb-28 mt-16 lg:mt-28">
          <div className="flex flex-col relative overflow-x-visible">
            <div className="mx-auto md:w-4/5 z-10 md:pr-[40%]">
              <div className="">
                <div className="text-dark_gray t_lg px-4 py-1 border border-primary rounded-lg mb-3 w-fit">
                  Stage 1
                </div>
                <h2 className="text-dark_purple font-bold mb-8">
                  PHONE OR VIDEO INTERVIEW
                </h2>
                <p className="t_lg">
                  You'll get an opportunity to have a conversation with our
                  recruitment team, who will walk you through our complete
                  process. You can choose to connect either via phone or video,
                  according to your preference. During this discussion, our team
                  will provide in-depth insights about the role, our business,
                  and the team you'll become a part of. This is also a valuable
                  moment for us to learn about you, your motivations, and your
                  prior experiences, helping us gauge whether we're a compatible
                  match.
                </p>
              </div>
              <div className="mt-10 md:mt-20">
                <div className="text-dark_gray t_lg px-4 py-1 border border-primary rounded-lg mb-3 w-fit">
                  Stage 2
                </div>
                <h2 className="text-dark_purple font-bold mb-8">
                  MANAGER & LEADERSHIP INTERVIEW
                </h2>
                <p className="t_lg">
                  The subsequent phase will involve an interview with your
                  potential future manager, possibly accompanied by another team
                  member. During this interview, they will evaluate both your
                  behavioral and technical aptitude skills, and engage in a more
                  comprehensive discussion regarding the role's expectations.
                </p>
              </div>
            </div>
            <img
              src={culture_mask}
              alt="Cover"
              className="hidden md:block w-auto h-auto absolute right-0 translate-x-1/2 -translate-y-1/3 z-0"
            />
          </div>

          <div className="lg:bottom-0 mt-10 md:mt-20 mb-10 mx-auto md:w-4/5">
            <div className="md:grid md:gap-16 md:grid-cols-2">
              <div className="">
                <div className="text-dark_gray t_lg px-4 py-1 border border-primary rounded-lg mb-3 w-fit">
                  Stage 3
                </div>
                <h2 className="text-dark_purple font-bold mb-8">
                  TASK & MEET THE TEAM
                </h2>
                <p className="t_lg">
                  We prioritize team culture. You'll have the opportunity to
                  undertake a prospective task and interact with members of the
                  broader team, including those you'll collaborate with closely.
                </p>
              </div>

              <div className="mt-12 md:mt-0">
                <div className="text-dark_gray t_lg px-4 py-1 border border-primary rounded-lg mb-3 w-fit">
                  Stage 4
                </div>
                <h2 className="text-dark_purple font-bold mb-8">JOB OFFER</h2>
                <p className="t_lg">
                  Congratulations! If you've reached this stage, a member of our
                  recruitment team will contact you to extend the job offer and
                  provide a thorough overview of all the essential details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
