import { Helmet } from "react-helmet-async";
import Routes from "./routes";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="w-full h-full bg-white relative">
      <Helmet titleTemplate="Avallo" defaultTitle="Avallo">
        <meta name="description" content="Avallo application" />
      </Helmet>
      <NavBar />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
