import { Helmet } from "react-helmet-async";
import background_culture from "../../../assets/images/culture/background_culture.png";
import { WhyYOuLoveWorkingHere } from "./components/WhyYOuLoveWorkingHere";
import { HowWeHire } from "./components/HowWeHire";
import { Stage } from "./components/Stage";
import Section from "../../components/Layout";

export const Culture = () => {
  return (
    <div className="flex flex-col bg-white">
      <Helmet titleTemplate="Culture" defaultTitle="Culture">
        <meta name="description" content="Culture application" />
      </Helmet>
      <div className="w-full relative">
        <img
          src={background_culture}
          alt="Background Culture"
          className="w-full h-full object-cover z-0 absolute"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-30% to-transparent"></div>
        <Section>
          <div className="flex flex-col w-full lg:w-4/5 mx-auto py-14 md:py-20">
            <p className="text-white text-[48px] md:text-[100px] lg:text-[180px] z-10 leading-none mb-14 lg:mb-28">
              Culture
            </p>
            <h2 className="font-thin text-white mb-12">
              Why You’ll Love Working Here
            </h2>
            <p className="t_lg text-white lg:w-1/2 font-light">
              As we expand, Ovallo remains dedicated to fostering a workplace
              where each and every employee experiences safety, inclusivity, and
              empowerment to achieve their career's pinnacle. We've cultivated a
              nurturing and diverse atmosphere that promotes collaboration and
              the open exchange of knowledge.
            </p>
          </div>
        </Section>
      </div>

      <WhyYOuLoveWorkingHere />
      <HowWeHire />
      <Stage />
    </div>
  );
};
