import Partner1 from "../../../../../assets/images/partners/1.png";
import Partner2 from "../../../../../assets/images/partners/2.png";
import Partner3 from "../../../../../assets/images/partners/3.png";
import Partner4 from "../../../../../assets/images/partners/4.png";
import Partner5 from "../../../../../assets/images/partners/5.png";

import OvalloIconBlack from "../../images/ovallo-icon-black.png";
import Ellipse from "../../../../../assets/images/ovalloIn/Ellipse.png";
import Group from "../../../../../assets/images/ovalloIn/Group.png";
import Section from "../../../../components/Layout";

const OvalloInfo = () => {
  const ListItem = [Partner1, Partner2, Partner3, Partner4, Partner5];
  return (
    <Section>
      {/* <img
        src={Ellipse}
        alt="Mask"
        className="absolute left-0 top-0 -translate-x-1/2"
      /> */}
      <img src={Group} alt="Mask" className="absolute right-0 top-0" />
      <h2 className="text-dark_purple text-center mb-10">
        Ovallo In The Media
      </h2>

      <div className="grid grid-cols-3 lg:grid-cols-5 gap-14 mb-32 mx-auto w-4/5 justify-center items-center">
        {ListItem.map((x, index) => (
          <img key={index} src={x} alt="OvalloIcon" />
        ))}
      </div>

      <h2 className="text-dark_purple text-center mb-10">
        Doing Things Differently
      </h2>

      <div className="flex flex-col lg:flex-row items-center w-3/5 mx-auto gap-20">
        <div className="flex items-center w-auto">
          <img
            className="md:w-[162px] h-auto p-[25px] object-contain"
            src={OvalloIconBlack}
            alt="OvalloIconBlack"
          />
          <p className="t_40px font-extralight">Group</p>
        </div>

        <div className="hidden lg:block h-[100px] w-[2px] bg-dark_gray"></div>

        <div className="flex flex-1">
          <p className="t_lg">
            Ovallo is a leading startup that is the powerhouse behind the rapid
            growth of several prominent Fintech brands. We excel in high-value
            verticals and command market leadership in markets such as: Forex &
            Online Trading, iGaming and blockchain.
          </p>
        </div>
      </div>
    </Section>
  );
};

export default OvalloInfo;
