import About from "../pages/About";
import { Contact } from "../pages/Contact";
import { Culture } from "../pages/Culture";
import HomePage from "../pages/home";
import path from "./path";
import { useRoutes } from "react-router-dom";

export default function Router() {

    return useRoutes([
        {
            path: path.root,
            element: <HomePage />,
        },
        {
            path: path.about,
            element: <About />,
        },
        {
            path: path.culture,
            element: <Culture />,
        },
        {
            path: path.contact,
            element: <Contact />,
        },
    ]);
}
