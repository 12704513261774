import { Box, Grid } from "@mui/material";
import ArrowUpLong from "../../images/arrow_up_long.png";
import Section from "../../../../components/Layout";

const OpenRoles = () => {
  return (
    <Section>
      <div className="flex flex-col items-center justify-center py-20 md:py-40">
        <div className="mb-12 md:mb-24">
          <h2 className="text-dark_purple text-center">Open Roles</h2>
        </div>
        <div className="flex flex-col md:flex-row gap-10 md:w-4/5">
          <div className="flex justify-center flex-1">
            <div className="flex flex-col">
              <h2 className="text-primary">Senior Backend</h2>
              <h2 className="text-primary mb-4">Developer (NodeJS)</h2>
              <p className="t_base mb-9">London, UK</p>

              <button className="btn rounded-lg">Apply Here</button>
            </div>
          </div>

          <div className="hidden md:flex justify-center items-center">
            <img src={ArrowUpLong} alt="ArrowUpLong" />
          </div>

          <div className="flex justify-center flex-1">
            <div className="flex flex-col">
              <h2 className="text-primary">Senior SEO</h2>
              <h2 className="text-primary mb-4">Manager (LATAM)</h2>

              <p className="t_base mb-9">Remote, Brazil</p>

              <button className="btn rounded-lg">Apply Here</button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default OpenRoles;
