import about_1 from "../../../../../assets/images/about/about_1.png";
import about_2 from "../../../../../assets/images/about/about_2.png";
import about_3 from "../../../../../assets/images/about/about_3.png";
import Section from "../../../../components/Layout";

export const OurCoreValues = () => {
  return (
    <Section>
      <div>
        <div className="md:grid md:gap-20 md:grid-cols-2 my-14 md:my-28 md:w-4/5 mx-auto">
          <h2 className="text-dark_purple t_60px">Our Core Values</h2>
        </div>

        <div className="md:grid md:gap-20 md:grid-cols-2 mb-20 md:w-4/5 mx-auto">
          <div className="flex flex-col ml-auto">
            <h2 className="text-dark_purple mb-8">Get it Done</h2>
            <p className="t_lg">
              We aim for efficiency in our tasks, emphasizing the importance of
              efficiently addressing the most critical matters. What are your
              actions when no one else is observing?
            </p>
            <br />
            <br />
            <p className="t_lg">
              Our teams uphold principles of integrity and honesty, consistently
              considering the perspectives of others. We are self-driven,
              proactive individuals who do not passively await instructions.
              Operating as a hybrid remote workforce necessitates these
              qualities in every team member.
            </p>
          </div>
          <img src={about_1} alt="Cover" />
        </div>

        <div className="md:grid md:gap-20 md:grid-cols-2 mb-20 md:w-4/5 mx-auto">
          <img src={about_2} alt="Cover" />

          <div className="flex flex-col">
            <h2 className="text-dark_purple mb-8">
              Recognition and rewards for your input
            </h2>
            <p className="t_lg">
              We believe in the motto "Work Smarter, Play Better."
            </p>
            <br />
            <br />
            <p className="t_lg">
              We're a rapidly expanding company, driven by passion and an
              unyielding desire to thrive. These qualities are held in high
              regard, and the outcomes are a testament to their worth.
            </p>
            <br />
            <br />
            <p className="t_lg">
              Here, you'll find an abundance of opportunities for personal
              development, coupled with industry-leading bonuses that reward
              your dedication and effort. Your growth at Ovallo will never
              cease.
            </p>
          </div>
        </div>

        <div className="md:grid md:gap-20 md:grid-cols-2 mb-40 md:w-4/5 mx-auto">
          <div className="flex flex-col ml-auto">
            <h2 className="text-dark_purple mb-8 flex items-center gap-6">
              <svg
                width="27"
                height="25"
                viewBox="0 0 27 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 24L24 24.5L24.5 24.5L24.5 24L24 24ZM24 -1.04907e-06L21.1132 5L26.8868 5L24 -1.04907e-06ZM2.18557e-08 24.5L24 24.5L24 23.5L-2.18557e-08 23.5L2.18557e-08 24.5ZM24.5 24L24.5 4.5L23.5 4.5L23.5 24L24.5 24Z"
                  fill="#37246D"
                />
              </svg>
              Level Up
            </h2>
            <p className="t_lg">
              We consistently drive innovation, both in our products and in our
              personal growth. Our teams proactively assume ownership of their
              self-development, and with our support, they have achieved
              remarkable feats.
            </p>
            <br />
            <br />
            <p className="t_lg">
              We possess a deep passion for learning and an unwavering
              commitment to continuous improvement. We embrace individuals with
              fresh ideas and their own initiatives. In fact, we've even
              provided support for numerous staff projects over the years.
            </p>
            <br />
            <br />
            <p className="t_lg">
              Our teams proactively assume ownership of their self-development,
              and with our support, they have achieved remarkable feats.
            </p>
          </div>
          <div className="flex items-center">
            <img src={about_3} alt="Cover" />
          </div>
        </div>
      </div>
    </Section>
  );
};
