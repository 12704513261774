const path = {
  root: '/',
  all: '*',
  
  about: '/about',
  culture: '/culture',
  contact: '/contact'
};

export default path;
