import Section from "../../../../components/Layout";
import home_mask_2 from "../../images/home_mask_2.png";
import mask from "../../images/home_banner_mask.png";

const Banner = () => {
  return (
    <div className="bg-primary">
      <Section>
        <div className="flex flex-row w-full">
          <div className="w-full md:w-7/12">
            <div className="flex flex-col mt-10 mb-20 items-center md:items-start z-10 ml-auto w-11/12">
              <p className="t_section text-white text-center md:text-left z-10 mb-10">
                Building The{" "}
                <span className="underline text-white">Future</span>
                <br /> Step By Step
              </p>
              <p className="t_2xl text-white text-center md:text-left z-10">
                Ovallo is one of the world’s leading startups behind powerful
                brands in the Fintech, Gaming and Blockchain industry.
              </p>
            </div>
          </div>
          <div className="hidden w-5/12 md:flex flex-col justify-center relative z-10">
            <img
              src={mask}
              alt="Banner Mask"
              className="h-full w-auto object-contain"
            />
          </div>
        </div>

        <img
          src={home_mask_2}
          alt="step_by_step_left"
          width="auto"
          className="h-full w-auto absolute left-0 z-0"
        />
      </Section>
    </div>
  );
};

export default Banner;
