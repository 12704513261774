import { Box } from "@mui/material";
import LetTalkBackground from "../../images/let_talk_background.png";
import ArrowRight from "../../../../../assets/images/ovalloIn/arrow-right.png";
import UnderlinedBrand from "../../../../../assets/images/ovalloIn/underlined-brands.png";
import UnderlinedCountries from "../../../../../assets/images/ovalloIn/underlined-countries.png";
import UnderlinedBrandPartner from "../../../../../assets/images/ovalloIn/underlined-partner.png";
import UnderlinedBrandTeamMember from "../../../../../assets/images/ovalloIn/underlined-team-member.png";
import Section from "../../../../components/Layout";

const LetTalkNumbers = () => {
  const renderTitle = () => {
    return (
      <div className="flex items-center justify-between z-10 mb-14 sm:px-10 md:px-20 relative">
        <div className="flex justify-center items-center">
          <img src={ArrowRight} alt="ArrowRight" className="mr-2 mb-5" />
          <h2 className="text-white">Let's Talk Numbers</h2>
        </div>
        <div className="absolute right-0 hidden md:flex">
          {[30, 60, 30, 90, 30, 0].map((e, index) => (
            <Box
              key={index}
              style={{
                width: 16,
                height: 16,
                backgroundColor: "white",
                borderRadius: 8,
                marginRight: `${e}px`,
              }}
            ></Box>
          ))}
        </div>
      </div>
    );
  };

  const renderUnderline = (name: string) => {
    switch (name) {
      case "Brands":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginLeft: "40px",
            }}
          >
            <img
              src={UnderlinedBrand}
              alt="UnderlinedBrand"
              style={{ width: 66, height: 7 }}
            />
          </div>
        );
      case "Countries":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginRight: "40px",
            }}
          >
            <img
              src={UnderlinedCountries}
              alt="UnderlinedBrand"
              style={{ width: 41, height: 7 }}
            />
          </div>
        );
      case "Partnerships":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={UnderlinedBrandPartner}
              alt="UnderlinedBrand"
              style={{ width: 174, height: 7 }}
            />
          </div>
        );
      case "Team Members":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              marginLeft: "90px",
            }}
          >
            <img
              src={UnderlinedBrandTeamMember}
              alt="UnderlinedBrand"
              style={{ width: 70, height: 7 }}
            />
          </div>
        );

      default:
        break;
    }
  };

  const renderNumberItem = (num: string, name: string) => {
    return (
      <div className="flex flex-col">
        <p className="t_100px text-center text-white leading-[5rem] md:leading-[10rem]">
          {num}
        </p>
        <h2 className="text-center text-white">{name}</h2>
        {renderUnderline(name)}
      </div>
    );
  };

  const renderNumbers = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 z-10 gap-10">
        {renderNumberItem("3", "Brands")}
        {renderNumberItem("71", "Countries")}
        {renderNumberItem("80+", "Partnerships")}
        {renderNumberItem("30", "Team Members")}
      </div>
    );
  };

  return (
    <div className="relative bg-primary">
      <Section className="py-28">
        <img
          src={LetTalkBackground}
          alt="LetTalkBackground"
          className="absolute inset-0 z-0 w-auto h-full object-cover"
        />

        {renderTitle()}
        {renderNumbers()}
      </Section>
    </div>
  );
};

export default LetTalkNumbers;
