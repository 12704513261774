import about_head_background_1 from "../../../../../assets/images/about/about_head_background_1.png";
import Section from "../../../../components/Layout";

export const Introduce = () => {
  return (
    <div className="bg-primary">
      <Section className="pt-12 md:pt-[220px] pb-12 md:pb-[190px]">
        <img
          src={about_head_background_1}
          alt="About Introduce"
          className="inset-0 absolute object-contain h-auto w-full"
        />

        <div className="flex flex-col md:flex-row gap-10 md:gap-28">
          <div className="flex-1">
            <p className="text-white t_60px font-normal mb-10 md:mb-20">
              About
            </p>
            <p className="text-white t_lg">
              Ovallo is a leading startup that is the powerhouse behind the
              rapid growth of several prominent Fintech brands. We excel in
              high-value verticals and command market leadership in markets such
              as: Forex & Online Trading, iGaming and blockchain.
            </p>
          </div>
          <div className="flex-1">
            <p className="text-white t_lg">
              We're an energetic, fast-paced, and swiftly expanding company.
              Egos and bureaucracy have no place here, but there are abundant
              opportunities to seize. Our atmosphere is both flexible and
              relaxed, infused with a results-oriented "can-do" spirit that
              promotes accountability and, most importantly, delivers results.
            </p>
            <br />
            <br />

            <p className="text-white t_lg">
              We prioritize staying nimble at all times, allowing us to respond
              to exciting market shifts and seize opportunities for entry into
              new markets.
            </p>
          </div>
        </div>
      </Section>
    </div>
  );
};
