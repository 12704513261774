import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { List } from "./components/List";
import logo_white from "../../../assets/logo/Narbar/logo_white.svg";
import logo_black from "../../../assets/logo/Narbar/logo_black.svg";

import { ArrowCircleRight, CirclesFour } from "@phosphor-icons/react";
import Section from "../Layout";

export const NavLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Culture",
    url: "/culture",
  },
  {
    name: "Contact",
    url: "/contact",
  },
];

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setOpen(!open);
  };

  const renderLogo = (pathname: string) => {
    switch (pathname) {
      case "/culture":
        return <img src={logo_black} alt="Logo" />;
      case "/contact":
        return <img src={logo_black} alt="Logo" />;
      default:
        return <img src={logo_white} alt="Logo" />;
    }
  };

  const renderBackground = (pathname: string) => {
    switch (pathname) {
      case "/culture":
        return "bg-white";
      case "/contact":
        return "bg-white";
      default:
        return "bg-primary";
    }
  };

  const renderColor = (pathname: string) => {
    switch (pathname) {
      case "/culture":
        return "text-[#303030]";
      case "/contact":
        return "text-[#303030]";
      default:
        return "text-white";
    }
  };

  const renderColorIcon = (pathname: string) => {
    switch (pathname) {
      case "/culture":
        return "#303030";
      case "/contact":
        return "#303030";
      default:
        return "currentColor";
    }
  };

  return (
    <header
      className={`font-PollyRoundedRegular font-normal w-full h-auto ${
        location.pathname && renderBackground(location.pathname)
      } overflow-x-hidden z-[100] top-0 left-0`}
    >
      <nav className={`w-full lg:h-28 md:h-24 h-20 flex items-center`}>
        <Section>
          <div className="flex justify-between items-center">
            <Link
              to={`/`}
              className="font-extrabold flex items-center relative md:text-2xl text-lg"
            >
              {location.pathname && renderLogo(location.pathname)}
            </Link>

            <div className="lg:flex hidden items-center h-full w-4/5">
              <ul className="flex items-center justify-evenly h-full relative w-full">
                {NavLinks.map((nav, index) => (
                  <List
                    className="w-full text-base flex justify-center"
                    key={index}
                  >
                    <NavLink
                      to={nav.url}
                      className={`relative inline-block px-2 whitespace-nowrap ${
                        location.pathname && renderColor(location.pathname)
                      } uppercase t_base transition-all duration-200 active:text-amber-500 hover:text-amber-500 from-red-500 to-amber-500`}
                    >
                      {nav.name}
                    </NavLink>
                  </List>
                ))}
              </ul>
            </div>
            <div
              className="hamburger lg:hidden flex text-white cursor-pointer"
              onClick={handleToggle}
            >
              <CirclesFour
                size={30}
                color={location.pathname && renderColorIcon(location.pathname)}
                weight="light"
              />
            </div>
          </div>
        </Section>
      </nav>

      {/* Mobile Nav  */}
      <nav
        className={`flex justify-end lg:hidden h-screen w-full bg-gray-950/90 fixed top-0 z-[100] ${
          open ? "right-0" : "-right-[120vw]"
        } transition-all duration-500 ease-out`}
      >
        <div
          className={`w-full md:w-[50%] h-screen bg-zinc-900 flex flex-col justify-between items-center relative ${
            open ? "right-0" : "-right-[120vw]"
          } transition-all duration-500 ease-out delay-300`}
        >
          <section className="w-full px-4 py-6 flex flex-col gap-16">
            <div className="w-full flex pt-5 px-4 justify-between items-center">
              <Link
                to={`/`}
                className="font-extrabold flex items-center relative md:text-2xl text-lg"
              >
                <img src={logo_white} />
              </Link>
              <div
                className="hamburger text-white cursor-pointer"
                onClick={handleToggle}
              >
                <ArrowCircleRight
                  size={25}
                  color="currentColor"
                  weight="light"
                />
              </div>
            </div>
            <ul className="flex flex-col gap-3 pl-5">
              {NavLinks.map((navLink, index) => (
                <List className="w-full text-base" key={index}>
                  <NavLink
                    to={navLink.url}
                    onClick={handleToggle}
                    className={`relative overflow-hidden inline-block text-white before:w-full before:h-0.5 before:bg-color2 before:absolute before:bottom-0 before:-left-full before:rounded-full before:transition-all before:duration-200 before:ease-in hover:before:left-0 `}
                  >
                    {navLink.name}
                  </NavLink>
                </List>
              ))}
            </ul>
          </section>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
