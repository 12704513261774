import { Helmet } from "react-helmet-async";
import { Introduce } from "./components/Introduce";
import { OurCoreValues } from "./components/OurCoreValues";

const About = () => {
  return (
    <div
      className="bg-white"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet titleTemplate="About" defaultTitle="About">
        <meta name="description" content="Avallo application" />
      </Helmet>
      <Introduce />
      <OurCoreValues />
    </div>
  );
};

export default About;
