import approaching from "../../images/approaching.png";
import Section from "../../../../components/Layout";
import Ellipse from "../../../../../assets/images/ovalloIn/Ellipse.png";

const Approaching = () => {
  return (
    <Section>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full md:w-4/5 mx-auto py-10 md:py-20 gap-20">
        <img
          src={Ellipse}
          alt="Mask"
          className="absolute left-0 -translate-x-1/2 translate-y-1/2 z-0"
        />

        <div className="flex flex-col justify-center items-center md:items-start">
          <h2 className="text-dark_purple mb-8 text-center md:text-left">
            Approaching things with a unique perspective.
          </h2>
          <p className="text-dark_gray t_lg mb-10 text-center md:text-left">
            We embrace cutting-edge technology through experimentation and
            empower our employees with the resources to enhance their skill
            sets. Our aim is not only to challenge existing norms but also to
            lead a revolution in the online Fintech industry.
          </p>

          <button className="btn">About Us</button>
        </div>
        <div className="flex flex-col justify-center items-center md:items-end">
          <img
            src={approaching}
            alt="approaching"
            className="w-4/5 sm:w-full h-auto lg:w-4/5"
          />
        </div>
      </div>
    </Section>
  );
};

export default Approaching;
