import culture_1 from "../../../../../assets/images/culture/culture_1.png";

import Section from "../../../../components/Layout";

export const WhyYOuLoveWorkingHere = () => {
  return (
    <Section>
      <div className="mt-16 lg:mt-20 relative">
        <div className="md:grid gap-20 grid-cols-2 mt-8 mx-auto md:w-4/5">
          <div className="flex flex-col justify-center">
            <p className="t_lg mb-4">
              We're dynamic, not conventional. We combine the vigor and audacity
              of a startup with the knowledge and practicality of a major
              corporation, all within a single entity.
            </p>
            <p className="t_lg">
              Our collaboration is a harmonious blend, creating something
              remarkable. It's our teamwork's vitality that sets us apart.
            </p>
          </div>
          <img src={culture_1} alt="Why You Will Love Working Here" />
        </div>
      </div>
    </Section>
  );
};
