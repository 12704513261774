import OurVertical from "../../images/we-work-our-verticals.png";
import GroupLeft from "../../../../../assets/images/ovalloIn/GroupLeft.png";
import Section from "../../../../components/Layout";

const OurVerticals = () => {
  return (
    <Section className="items-center justify-center pt-32">
      <img
        src={GroupLeft}
        alt="GroupLeft"
        className="absolute left-0 top-0 -translate-y-1/4"
      />

      <div className="z-3 relative">
        <h2 className="text-dark_purple text-center mb-10">Our Verticals</h2>
        <img
          src={OurVertical}
          width="100%"
          alt="OurVertical"
          className="mb-40"
        />
      </div>
    </Section>
  );
};

export default OurVerticals;
