import { Helmet } from "react-helmet-async";
import img_left_contact from "../../../assets/images/contact/img_left_contact.png";
import img_right_contact from "../../../assets/images/contact/img_right_contact.png";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import Section from "../../components/Layout";

export const Contact = () => {
  const [value, setValue] = React.useState("female");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div className="bg-white md:py-[80px] lg:pt-[90px] px-8 lg:px-0">
      <Helmet titleTemplate="Contact" defaultTitle="Contact">
        <meta name="description" content="Contact application" />
      </Helmet>

      <Section>
        <div className="hidden lg:block">
          <img
            src={img_left_contact}
            alt="Contact"
            className="absolute top-0 left-0 -translate-x-1/2"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 w-full gap-10">
          <div className="z-10 col-span-5">
            <p className="uppercase text-primary t_base mb-8">Let's Connect</p>
            <p className="text-dark_purple t_40px font-semibold mb-8">
              Have an question for us?
            </p>
            <p className="text-black/80 t_base">
              Reach us for network participation, advertising partnerships,
              event sponsorship, interactions with our editorial team, and more.
            </p>
          </div>

          <div className="col-span-7">
            <div className="grid grid-cols-1 gap-4">
              <div className="t_base font-thin">First Name</div>
              <input
                type="text"
                placeholder="Enter your first name"
                className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full"
              />
              <div className="t_base font-thin">Last Name</div>
              <input
                type="text"
                placeholder="Enter your last name"
                className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full"
              />
              <div className="t_base font-thin">Company Name</div>
              <input
                type="text"
                placeholder="Enter your company name"
                className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full"
              />
              <div className="t_base font-thin">Email</div>
              <input
                type="text"
                placeholder="Enter your email"
                className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full"
              />
              <div className="t_base font-thin">Phone</div>
              <input
                type="text"
                placeholder="Enter your phone number"
                className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full"
              />
            </div>
            <div className="mt-12">
              <div className="t_base font-thin">How can we help?</div>
              <div className="px-0 py-4">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="advertise_on_our_sites"
                      control={<Radio />}
                      label={
                        <p className="t_base font-thin">
                          Advertise on our sites
                        </p>
                      }
                      color="default"
                    />
                    <FormControlLabel
                      value="sponsor_an_event"
                      control={<Radio />}
                      label={
                        <p className="t_base font-thin">Sponsor an event</p>
                      }
                      color="default"
                    />
                    <FormControlLabel
                      value="connect_our-editors"
                      control={<Radio />}
                      label={
                        <p className="t_base font-thin">Connect our editors</p>
                      }
                      color="default"
                    />
                    <FormControlLabel
                      value="join_our_collective"
                      control={<Radio />}
                      label={
                        <p className="t_base font-thin">Join our collective</p>
                      }
                      color="default"
                    />
                    <FormControlLabel
                      value="report_an_issue"
                      control={<Radio />}
                      label={
                        <p className="t_base font-thin">Report an issue</p>
                      }
                      color="default"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="mt-12 z-10">
              <p className="t_base font-thin">Message</p>
              <div className="mt-4">
                <textarea
                  placeholder="Enter your message..."
                  rows={10}
                  className="bg-[#F0F0F0] t_base font-thin focus:outline-none px-4 py-2 w-full z-10"
                ></textarea>
              </div>
            </div>
            <button className="btn mt-8 z-10">Submit</button>

            <p className="t_base font-thin mt-8 z-10">
              Have all inquiries sent to office@ovallo.io
            </p>
          </div>
        </div>

        <div className="hidden lg:flex flex-col justify-end absolute bottom-0 right-0 z-0">
          <img src={img_right_contact} alt="Contact" />
        </div>
      </Section>
    </div>
  );
};
