import SoftwareIcon from "../../images/1.png";
import GameDevelopIcon from "../../images/2.png";
import DesignIcon from "../../images/3.png";
import ProductIcon from "../../images/4.png";
import UIUXDesignIcon from "../../images/5.png";
import GraphQLIcon from "../../images/we-work-graphQL.png";
import JavaScriptIcon from "../../images/we-work-javaScript.png";
import KubernetesIcon from "../../images/we-work-kubernetes.png";
import NodejsIcon from "../../images/we-work-nodejs.png";
import RedisIcon from "../../images/we-work-redis.png";
import SqlIcon from "../../images/we-work-sql.png";
import SvelteIcon from "../../images/we-work-svelte.png";
import TypeScriptIcon from "../../images/we-work-typeScript.png";
import Section from "../../../../components/Layout";

const WeWorkingIn = () => {
  const renderWork = (data: { text: string; icon: string }) => {
    return (
      <div
        className="flex flex-col items-center h-full justify-start px-5"
        key={data.text}
      >
        <div className="flex items-center justify-center">
          <img
            src={data.icon}
            alt={data.icon}
            className="h-14 w-auto object-cover mb-11"
          />
        </div>
        <p className="t_2xl font-semibold text-center">{data.text}</p>
      </div>
    );
  };

  const renderProgram = (data: { text: string; icon: string }) => {
    return (
      <div className="flex flex-col items-center justify-between h-full shadow-xl bg-white rounded-3xl p-6">
        <img src={data.icon} alt={data.icon} />
        <div className="t_2xl font-semibold">{data.text}</div>
      </div>
    );
  };

  return (
    <>
      <Section className="pt-32">
        <h2 className="text-dark_purple text-center mb-10">We work in</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 divide-x gap-y-10">
          {[
            {
              text: "Software",
              icon: SoftwareIcon,
            },
            {
              text: "Game\nDevelopment",
              icon: GameDevelopIcon,
            },
            {
              text: "Design",
              icon: DesignIcon,
            },
            {
              text: "Product\nDevelopment",
              icon: ProductIcon,
            },
            {
              text: "UX/UI\nDesign",
              icon: UIUXDesignIcon,
            },
          ].map((x) => renderWork(x))}
        </div>
      </Section>

      <Section className="pt-32">
        <h2 className="mb-10 text-dark_purple text-center">
          Some of the programs we use
        </h2>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-14">
          {[
            {
              text: "Svelte",
              icon: SvelteIcon,
            },
            {
              text: "Node js",
              icon: NodejsIcon,
            },
            {
              text: "TypeScript",
              icon: TypeScriptIcon,
            },
            {
              text: "Redis",
              icon: RedisIcon,
            },
            {
              text: "Kubernetes",
              icon: KubernetesIcon,
            },
            {
              text: "GraphQL",
              icon: GraphQLIcon,
            },
            {
              text: "Javascript",
              icon: JavaScriptIcon,
            },
            {
              text: "SQL",
              icon: SqlIcon,
            },
          ].map((x) => renderProgram(x))}
        </div>
      </Section>
    </>
  );
};

export default WeWorkingIn;
