import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import './styles/css/global.css'

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
   </React.StrictMode>
  </HelmetProvider> 
);
